// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function mute(cont) {
  const {title} = window;
  var btn = $(
    '<button id="muteBtn" type="button" class="btn btn-light"><i class="icon fa-regular fa-moon fa-fw"></i></button>'
  )
    .appendTo(cont)
    .addTooltip(info.tooltip.mute)
    .on(click, function () {
      btn.toggleClass('active');
      if (localStorage.getItem('mute')) {
        localStorage.removeItem('mute');
        if (window.timerRunning) localStorage.removeItem('timerMute');
      } else {
        localStorage.setItem('mute', true);
        if (window.timerRunning) localStorage.setItem('timerMute', true);
      }
      setTitle(title);
    });
  if (localStorage.getItem('mute')) {
    btn.addClass('active');
    setTitle(title);
  }
}
